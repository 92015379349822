<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="lg"
      :opacity=".7"
    >
      <ValidationObserver
        v-if="!loading"
        ref="form"
      >
        <Form
          :journal-data.sync="formData"
          @formSubmit="doFormSave"
        />
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import _ from 'lodash'
import axiosIns from '@/libs/axios'
import store from '@/store'
import { FormDataConverter } from '@/mixins/formData'
import Form from './Form.vue'

const emptyFormData = Object.freeze({
  id: null,
  slug: '',
  name: '',
  description: '',
  seo_title: '',
  seo_description: '',
})

export default {
  name: 'MedJournalUpdate',
  components: {
    BCard,
    BOverlay,
    Form,
  },
  metaInfo: {
    title: 'Редактирование Мед-Журнала',
  },
  data() {
    return {
      formData: _.cloneDeep(emptyFormData),
      loading: true,
      serverErrors: {},
    }
  },
  mounted() {
    Promise.all([
      this.doLoadMedJournal(),
    ]).then(() => {
      this.loading = false
    })
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    async doFormSave(callbackData) {
      this.loading = true

      const prepareFormData = _.merge(this.formData, { textEditorAttachments: callbackData.textEditorAttachments })
      const formData = new FormDataConverter(prepareFormData)

      try {
        const res = await axiosIns({
          method: 'POST',
          url: `medJournal/${this.$route.params.id}/update`,
          data: formData.getData(),
        })
        this.loading = false
        if (res.data.status === 'success') {
          await this.$router.push({ name: 'med-journal' })
          this.sendToast({
            title: 'Успех!',
            icon: 'CheckCircleIcon',
            text: 'Страница в Мед. журнале успешно обновлена!',
            variant: 'success',
          })
        }
      } catch (error) {
        this.loading = false
        if (error.response.status === 500) {
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Возможно такая категория уже существует. Попробуйте снова!',
            variant: 'danger',
          })
        }
        if (error.response.status === 422) {
          this.serverErrors = error.response.data.data
          this.$refs.form.setErrors(this.serverErrors)
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Не все поля заполнены!',
            variant: 'danger',
          })
        }
      }
    },
    doLoadMedJournal() {
      const req = axiosIns({
        method: 'GET',
        url: `/medJournal/${this.$route.params.id}/show`,
      })

      req.then(response => {
        this.formData = _.merge(this.formData, response.data.data)
        store.commit('app/UPDATE_CURRENT_PAGE_TITLE', this.formData.name)
      })

      return req
    },
  },
}
</script>
