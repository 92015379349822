<template>
  <b-form @submit.prevent>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Название:"
          label-for="name"
        >
          <ValidationProvider
            #default="{ errors }"
            name="name"
            vid="name"
          >
            <b-form-input
              id="name"
              v-model="journalData.name"
              autocomplete="off"
              placeholder="Введите название"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="ЧПУ URL:"
          label-for="slug"
        >
          <ValidationProvider
            #default="{ errors }"
            name="slug"
            vid="slug"
          >
            <b-form-input
              id="slug"
              v-model="journalData.slug"
              autocomplete="off"
              placeholder="Введите ЧПУ URL"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Содержание страницы:"
          label-for="description"
        >
          <ValidationProvider
            #default="{ errors }"
            name="description"
            vid="description"
          >
            <froala
              ref="froalaEditor-description"
              v-model="journalData.description"
              :tag="'textarea'"
              :config="froalaConfig"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Мета title:"
          label-for="seo_title"
        >
          <ValidationProvider
            #default="{ errors }"
            name="seo_title"
            vid="seo_title"
          >
            <b-form-input
              id="title"
              v-model="journalData.seo_title"
              autocomplete="off"
              placeholder="Введите мета Title страницы"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Meta Description (max 255 символов):"
          label-for="seo_description"
        >
          <ValidationProvider
            #default="{ errors }"
            name="seo_description"
            vid="seo_description"
          >
            <b-form-textarea
              id="description"
              v-model="journalData.seo_description"
              placeholder="Введите мета Description страницы"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-button
          variant="primary"
          class="float-right"
          @click="formSubmit"
        >
          {{ journalData.id ? 'Обновить и закрыть' : 'Создать и закрыть' }}
        </b-button>
      </div>
      <div>
        <b-button
          variant="flat-danger"
          class="mr-1"
          @click="$router.back()"
        >
          Отменить
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import froalaMixin from '@/mixins/froalaMixin'

export default {
  name: 'MedJournalForm',
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
  },
  mixins: [
    froalaMixin,
  ],
  props: {
    journalData: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  mounted() {
    this.froalaInstance = this.$refs['froalaEditor-description'].getEditor()

    this.$set(this.froalaInstance.opts, 'imageUploadParams', {
      model_name: 'App\\Models\\MedicalJournal',
      squad: 5,
    })
  },
  methods: {
    formSubmit() {
      this.$emit('formSubmit', {
        textEditorAttachments: this.froalaAttachments,
      })
    },
  },
}
</script>
