var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Название:","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"name","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Введите название"},model:{value:(_vm.journalData.name),callback:function ($$v) {_vm.$set(_vm.journalData, "name", $$v)},expression:"journalData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"ЧПУ URL:","label-for":"slug"}},[_c('ValidationProvider',{attrs:{"name":"slug","vid":"slug"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slug","autocomplete":"off","placeholder":"Введите ЧПУ URL"},model:{value:(_vm.journalData.slug),callback:function ($$v) {_vm.$set(_vm.journalData, "slug", $$v)},expression:"journalData.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Содержание страницы:","label-for":"description"}},[_c('ValidationProvider',{attrs:{"name":"description","vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('froala',{ref:"froalaEditor-description",attrs:{"tag":'textarea',"config":_vm.froalaConfig},model:{value:(_vm.journalData.description),callback:function ($$v) {_vm.$set(_vm.journalData, "description", $$v)},expression:"journalData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Мета title:","label-for":"seo_title"}},[_c('ValidationProvider',{attrs:{"name":"seo_title","vid":"seo_title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","autocomplete":"off","placeholder":"Введите мета Title страницы"},model:{value:(_vm.journalData.seo_title),callback:function ($$v) {_vm.$set(_vm.journalData, "seo_title", $$v)},expression:"journalData.seo_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Meta Description (max 255 символов):","label-for":"seo_description"}},[_c('ValidationProvider',{attrs:{"name":"seo_description","vid":"seo_description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Введите мета Description страницы","rows":"3"},model:{value:(_vm.journalData.seo_description),callback:function ($$v) {_vm.$set(_vm.journalData, "seo_description", $$v)},expression:"journalData.seo_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('div',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.journalData.id ? 'Обновить и закрыть' : 'Создать и закрыть')+" ")])],1),_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Отменить ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }